import fp from "lodash/fp";

export function generateMarkerRadius(count, zoom) {
  const minWidth = 35;
  const maxWidth = 100;
  let totalCount = 100000;
  const delta = maxWidth - minWidth;

  switch (zoom) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      totalCount = 300000;
      break;
    case 7:
    case 8:
      totalCount = 200000;
      break;
    case 9:
      totalCount = 150000;
      break;
    case 10:
      totalCount = 60000;
      break;
    case 11:
      totalCount = 25000;
      break;
    case 12:
      totalCount = 13000;
      break;
    case 13:
      totalCount = 5000;
      break;
    case 14:
      totalCount = 3000;
      break;
    default:
      totalCount = 2000;
      break;
  }
  return minWidth + fp.toInteger(delta * count / totalCount);
}

export function generateColorCode(size) {
  const minWidth = 35;
  const maxWidth = 100;
  const maxCode = 255;
  const minCode = 0;

  const step = maxCode / (maxWidth - minWidth);

  const gCodeRed = fp.toInteger(minCode + step * (size - minWidth));
  const gCodeBlue = fp.toInteger(maxCode - step * (size - minWidth));

  return `rgb(${gCodeRed}, ${minCode}, ${gCodeBlue})`;
}

export function getCountryMapOptions(country) {
  switch (country) {
    case "KSA":
      return { center: { lat: 23.8859, lng: 45.0792 }, zoom: 6 };
    case "bh":
      return { center: { lat: 26.0667, lng: 50.5577 }, zoom: 8 };
    case "EGP":
      return { center: { lat: 31.205753, lng: 29.924526 }, zoom: 8 };
    case "kw":
      return { center: { lat: 29.3117, lng: 47.4818 }, zoom: 8 };
    case "om":
      return { center: { lat: 21.4735, lng: 55.9754 }, zoom: 8 };
    case "qa":
      return { center: { lat: 25.3548, lng: 51.1839 }, zoom: 8 };
    default:
      return { center: { lat: 25.2048, lng: 55.2708 }, zoom: 8 };
  }
}

export function divideBy(period) {
  switch (period) {
    case "Daily":
    case "Monthly":
    default:
      return 1;
  }
}

export function getWarehouseColor(wh) {
  switch (fp.lowerCase(wh)) {
    case "q express":
      return "rgb(136,0,21)";
    case "wing pending":
      return "rgb(96,96,96)";
    default:
      return "rgb(0,64,0)";
  }
}

import fp from "lodash/fp";
import DataListFilter, { getListFilterSchema } from "./DataListFilter";
import {
  types,
  mapSchema,
  queryTypes,
} from "../../shared/helpers/ObjectMapper";

export const toNeighborhoodBetaFilter = fp.compose(
  DataListFilter.create,
  mapSchema({
    ...getListFilterSchema(),

    courier: types.string,
    country: types.string,
    shippingServices: types.string,
    excludeShippingServices: types.string,
    couriers: types.string,
    excludeCouriers: types.string,
    sellerType: types.string,
    hidePoints: types.boolean,
    customer_ids: types.string,
    exclude_customer_ids: types.string,
    supplier_ids: types.string,
    exclude_supplier_ids: types.string,

    fromDate: queryTypes.date,
    toDate: queryTypes.date,
  }),
);

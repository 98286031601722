import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { generateColorCode, generateMarkerRadius } from "../MarkerHelper";
import { formatNumber } from "../../../helpers/FormatUtils";

const ordersCount = points => {
  let totalCount = 0;
  points.forEach(item => {
    totalCount += item.count || 0;
  });
  return totalCount;
};

const enhancer = compose(
  useSheet({
    markerInGroup: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
      padding: "4px",
      marginLeft: "-10px",
      textAlign: "center",
      fontSize: "10px",
      color: "#fff",
      border: "1px solid #ff0000",
    },
    wrapper: {
      width: "auto",
      backgroundColor: "transparent",
      zIndex: "100",
    },
  }),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
);

class ClusterMarker extends React.PureComponent {
  render() {
    const { classes } = this.props;
    const totalPoints =
      this.props.points.length > 0 ? ordersCount(this.props.points) : 0;

    const size = totalPoints
      ? generateMarkerRadius(totalPoints, this.props.zoom)
      : 0;
    const colorCode = totalPoints ? generateColorCode(size) : 0;

    return (
      <div className={classes.wrapper}>
        {totalPoints > 0 && (
          <div
            className={classes.markerInGroup}
            style={{
              height: `${size > 70 ? 70 : size}px`,
              width: `${size > 70 ? 70 : size}px`,
              backgroundColor: colorCode,
              zIndex: "10",
            }}
          >
            {formatNumber(fp.ceil(totalPoints / this.props.division))}
          </div>
        )}
      </div>
    );
  }
}

ClusterMarker.propTypes = {
  points: PropTypes.array,
  classes: PropTypes.object,
  zoom: PropTypes.number,
  division: PropTypes.number,
};

export default enhancer(ClusterMarker);

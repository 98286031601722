import React from "react";
import { connect } from "react-redux";
import ToolsSignIn from "./ToolsSignIn";
import { fetchUser } from "../../actions/ProfileActions";
import DashboardRoot from "../../components/app-core/DashboardRoot";

function AdminRoot(props) {
  return <DashboardRoot {...props} SignIn={ToolsSignIn} />;
}

export default connect(null, { fetchUser })(AdminRoot);

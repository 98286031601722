import React from "react";
import { endOfToday, startOfToday } from "date-fns";
import { List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import {
  Card,
  CardContent,
  ListSubheader,
  Button,
  IconButton,
  CardActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import { AddCircle, RemoveCircle } from "@material-ui/icons";
import { red, lightGreen } from "@material-ui/core/colors";
import FormCheckbox from "../form/FormCheckbox";
import FormDateField from "../form/FormDateField";
import FormCustomerChips from "../form/FormCustomerChips";
import FormSupplierChips from "../form/FormSupplierChips";
import FlexBox from "../ui-core/FlexBox";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { getObjectId } from "../../helpers/FormUtils";
import { safeParseDate, formatDateToUrl } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import {
  parseString,
  parseIntString,
  stringifyArray,
} from "../../helpers/SerializeUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const idsToObjectArray = fp.flow(
  parseIntString,
  fp.map(id => ({ id })),
);

const styles = {
  chipAutoComplete: {
    chipContainer: {
      maxHeight: "96px",
      overflowY: "auto",
    },
  },
  subheader: { flex: "1 1 0%", paddingLeft: "0px" },
};

const startTime = startOfToday();
const endTime = endOfToday();

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return { getLocalisationMessage };
  }),
  useSheet({
    subheader: { flex: "1 1 0%", paddingLeft: "0px" },
    includeButton: { paddingBottom: "12px" },
    toggle: {
      marginTop: "13px",
      whiteSpace: "nowrap",
      "& label": { color: "#FFF" },
    },
  }),
  withContext(
    {
      getCachedCustomer: PropTypes.func,
      getCustomerPredictions: PropTypes.func,
      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,
    },
    props => ({
      getCachedCustomer: props.getCachedCustomer,
      getCustomerPredictions: props.getCustomerPredictions,
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
    }),
  ),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .map(props => {
        const x: DataListFilter = props.filter;

        const includeCustomers = Boolean(
          x.getValue("customer_ids") || !x.getValue("exclude_customer_ids"),
        );

        const includeShippingServices = Boolean(
          x.getValue("shippingServices") ||
            !x.getValue("excludeShippingServices"),
        );

        const includeSuppliers = Boolean(
          x.getValue("supplier_ids") || !x.getValue("exclude_supplier_ids"),
        );

        const includeParentSuppliers = Boolean(
          x.getValue("parent_supplier_ids") ||
            !x.getValue("exclude_parent_supplier_ids"),
        );

        const includeCouriers = Boolean(
          x.getValue("couriers") || !x.getValue("excludeCouriers"),
        );

        return {
          country: x.getValue("country"),
          sellerType: x.getValue("sellerType"),
          hidePoints: x.getBoolValue("hidePoints"),
          period: x.getValue("period"),
          fromDateTime: safeParseDate(x.getValue("fromDate")),
          toDateTime: safeParseDate(x.getValue("toDate")),

          includeShippingServices,
          shippingServices: includeShippingServices
            ? parseString(x.getValue("shippingServices"))
            : parseString(x.getValue("excludeShippingServices")),

          includeCouriers,
          couriers: includeCouriers
            ? parseString(x.getValue("couriers"))
            : parseString(x.getValue("excludeCouriers")),

          includeCustomers,
          customerIds: includeCustomers
            ? idsToObjectArray(x.getValue("customer_ids"))
            : idsToObjectArray(x.getValue("exclude_customer_ids")),

          includeSuppliers,
          supplierIds: includeSuppliers
            ? idsToObjectArray(x.getValue("supplier_ids"))
            : idsToObjectArray(x.getValue("exclude_supplier_ids")),

          includeParentSuppliers,
          parentSupplierIds: includeParentSuppliers
            ? idsToObjectArray(x.getValue("parent_supplier_ids"))
            : idsToObjectArray(x.getValue("exclude_parent_supplier_ids")),
        };
      })
      .distinctUntilChanged(isEqualData);

    const onSubmit = (values, dispatch, props) =>
      props.onFilterChange(
        props.filter.withMutations((filter: DataListFilter) => {
          filter.setValueMap({
            country: values.country,
            sellerType: values.sellerType,
            hidePoints: Boolean(values.hidePoints),
            period: values.period,
            couriers: null,
            shippingServices: null,
            includeCouriers: null,
            includeShippingServices: null,
            includeCustomers: null,
            excludeCouriers: null,
            excludeShippingServices: null,
            customer_ids: null,
            exclude_customer_ids: null,
            supplier_ids: null,
            exclude_supplier_ids: null,
            parent_supplier_ids: null,
            exclude_parent_supplier_ids: null,
            fromDate: formatDateToUrl(values.fromDateTime),
            toDate: formatDateToUrl(values.toDateTime),
          });

          if (!fp.isEmpty(values.couriers)) {
            if (values.includeCouriers) {
              filter.setValue("couriers", stringifyArray(values.couriers));
            } else {
              filter.setValue(
                "excludeCouriers",
                stringifyArray(values.couriers),
              );
            }
          }

          if (!fp.isEmpty(values.customerIds)) {
            if (values.includeCustomers) {
              filter.setValue(
                "customer_ids",
                stringifyArray(values.customerIds.map(getObjectId)),
              );
            } else {
              filter.setValue(
                "exclude_customer_ids",
                stringifyArray(values.customerIds.map(getObjectId)),
              );
            }
          }
          if (!fp.isEmpty(values.supplierIds)) {
            if (values.includeSuppliers) {
              filter.setValue(
                "supplier_ids",
                stringifyArray(values.supplierIds.map(getObjectId)),
              );
            } else {
              filter.setValue(
                "exclude_supplier_ids",
                stringifyArray(values.supplierIds.map(getObjectId)),
              );
            }
          }

          if (!fp.isEmpty(values.parentSupplierIds)) {
            if (values.includeParentSuppliers) {
              filter.setValue(
                "parent_supplier_ids",
                stringifyArray(values.parentSupplierIds.map(getObjectId)),
              );
            } else {
              filter.setValue(
                "exclude_parent_supplier_ids",
                stringifyArray(values.parentSupplierIds.map(getObjectId)),
              );
            }
          }

          if (!fp.isEmpty(values.shippingServices)) {
            if (values.includeShippingServices) {
              filter.setValue(
                "shippingServices",
                stringifyArray(values.shippingServices),
              );
            } else {
              filter.setValue(
                "excludeShippingServices",
                stringifyArray(values.shippingServices),
              );
            }
          }
        }),
      );

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,

        onSubmit,
        initialValues,
      }))
      .distinctUntilChanged(isEqualData);
  }),

  reduxForm({
    enableReinitialize: true,
    form: "NeighborhoodsFilterFormBeta",
  }),
  formValues({
    hidePoints: "hidePoints",
    couriers: "couriers",
    shippingServices: "shippingServices",
    includeCouriers: "includeCouriers",
    includeShippingServices: "includeShippingServices",
    includeCustomers: "includeCustomers",
    includeSuppliers: "includeSuppliers",
    includeParentSuppliers: "includeParentSuppliers",
    fromDateTime: "fromDateTime",
    toDateTime: "toDateTime",
  }),
  pureComponent(
    fp.pick([
      "shippingServices",
      "couriers",
      "includeShippingServices",
      "includeCouriers",
      "includeCustomers",
      "includeSuppliers",
      "includeParentSuppliers",
      "fromDateTime",
      "toDateTime",
    ]),
  ),
);

NeighborhoodsFilterFormBeta.propTypes = {
  classes: PropTypes.object,

  onDismiss: PropTypes.func,
  onFilterChange: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,

  shippingServices: PropTypes.array,
  includeShippingServices: PropTypes.bool,
  couriers: PropTypes.array,
  includeCouriers: PropTypes.bool,

  dirty: PropTypes.bool,
  reset: PropTypes.func,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,

  fromDateTime: PropTypes.any,
  toDateTime: PropTypes.any,

  includeCustomers: PropTypes.bool,
  getCustomerPredictions: PropTypes.func,

  includeSuppliers: PropTypes.bool,
  includeParentSuppliers: PropTypes.bool,
  getSupplierPredictions: PropTypes.func,

  countryList: PropTypes.instanceOf(List),
  getLocalisationMessage: PropTypes.func.isRequired,
};

function NeighborhoodsFilterFormBeta(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <FlexBox container={8} flex="none">
      <FlexBox gutter={8} flex={true} direction="column">
        <FlexBox flex={true} direction="column">
          <Card>
            <CardContent>
              <FlexBox gutter={8} flex={true} direction="column">
                {props.getCustomerPredictions && (
                  <FlexBox gutter={8} flex={true} align="flex-end">
                    <FlexBox flex={true}>
                      <FormCustomerChips
                        name="customerIds"
                        fullWidth={true}
                        hintText={getLocalisationMessage(
                          "type_to_search",
                          "Type to search ...",
                        )}
                        label={
                          props.includeCustomers
                            ? getLocalisationMessage(
                                "include_customers",
                                "Include Customers",
                              )
                            : getLocalisationMessage(
                                "exclude_customers",
                                "Exclude Customers",
                              )
                        }
                        chipContainerStyle={
                          styles.chipAutoComplete.chipContainer
                        }
                      />
                    </FlexBox>

                    <FlexBox align="flex-end" className={classes.includeButton}>
                      <IconButton
                        onClick={() =>
                          props.change(
                            "includeCustomers",
                            !props.includeCustomers,
                          )
                        }
                      >
                        {props.includeCustomers ? (
                          <AddCircle color={lightGreen[600]} />
                        ) : (
                          <RemoveCircle color={red[500]} />
                        )}
                      </IconButton>
                    </FlexBox>
                  </FlexBox>
                )}

                {props.getSupplierPredictions && (
                  <FlexBox gutter={8} flex={true} align="flex-end">
                    <FlexBox flex={true}>
                      <FormSupplierChips
                        fullWidth={true}
                        name="supplierIds"
                        hintText={getLocalisationMessage(
                          "type_to_search",
                          "Type to search ...",
                        )}
                        label={
                          props.includeCustomers
                            ? getLocalisationMessage(
                                "include_suppliers",
                                "Include Suppliers",
                              )
                            : getLocalisationMessage(
                                "exclude_suppliers",
                                "Exclude Suppliers",
                              )
                        }
                      />
                    </FlexBox>

                    <FlexBox align="flex-end" className={classes.includeButton}>
                      <IconButton
                        onClick={() =>
                          props.change(
                            "includeSuppliers",
                            !props.includeSuppliers,
                          )
                        }
                      >
                        {props.includeSuppliers ? (
                          <AddCircle color={lightGreen[600]} />
                        ) : (
                          <RemoveCircle color={red[500]} />
                        )}
                      </IconButton>
                    </FlexBox>
                  </FlexBox>
                )}
                <FlexBox flex={true} direction="column">
                  <ListSubheader style={styles.subheader}>
                    {getLocalisationMessage("period", "Period")}
                  </ListSubheader>

                  <FlexBox flex={true} gutter={8}>
                    <FlexBox gutter={8} flex={true}>
                      <FlexBox flex={true}>
                        <FormDateField
                          fullWidth={true}
                          name="fromDateTime"
                          hintText={getLocalisationMessage(
                            "from_date",
                            "From Date",
                          )}
                          initialTime={startTime}
                        />
                      </FlexBox>
                    </FlexBox>

                    <FlexBox gutter={8} flex={true}>
                      <FlexBox flex={true}>
                        <FormDateField
                          fullWidth={true}
                          hintText={getLocalisationMessage(
                            "to_date",
                            "To Date",
                          )}
                          initialTime={endTime}
                          name="toDateTime"
                        />
                      </FlexBox>
                    </FlexBox>
                  </FlexBox>
                </FlexBox>

                <FlexBox flex={true}>
                  <FlexBox gutter={8} flex={true}>
                    <FlexBox flex={true}>
                      <FlexBox gutter={8} flex={true}>
                        <FormCheckbox
                          name="hidePoints"
                          label={getLocalisationMessage(
                            "hide_pick_up_points",
                            "Hide PickUp Points",
                          )}
                        />
                      </FlexBox>
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            </CardContent>
          </Card>
        </FlexBox>

        <FlexBox justify="flex-end">
          <CardActions>
            <Button
              onClick={() => {
                fp.keys(props.initialValues).forEach(key => {
                  props.change(key, null);
                });
              }}
            >
              {getLocalisationMessage("clear", "Clear")}
            </Button>

            {props.dirty ? (
              <Button onClick={props.reset}>
                {getLocalisationMessage("reset", "Reset")}
              </Button>
            ) : (
              Boolean(props.onDismiss) && (
                <Button onClick={props.onDismiss}>
                  {getLocalisationMessage("dismiss", "Dismiss")}
                </Button>
              )
            )}
            <Button onClick={props.handleSubmit}>
              {getLocalisationMessage("submit", "Submit")}
            </Button>
          </CardActions>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(NeighborhoodsFilterFormBeta);

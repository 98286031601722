import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { Place, Home } from "@material-ui/icons";
import { getWarehouseColor } from "../MarkerHelper";
import { toJSON, toBase64 } from "../../../../shared/helpers/DataSerializer";

const enhancer = compose(
  useSheet({
    markerInGroup: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
      padding: "4px",
      marginLeft: "-10px",
      textAlign: "center",
      fontSize: "14px",
      color: "#fff",
      border: "1px solid #ff0000",
      backgroundColor: "blue",
    },
    markerSingle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
      padding: "4px",
      marginLeft: "-10px",
      textAlign: "center",
      fontSize: "14px",
      color: "#000",
      border: "1px solid #ff0000",
      backgroundColor: "yellow",
    },
  }),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
);

class OutLitesMarker extends React.PureComponent {
  render() {
    return (
      <div style={{ zIndex: "0" }}>
        {fp.lowerCase(this.props.item.type) === "fc" ? (
          <Home
            size={100}
            color="#008bff"
            style={{ height: "45px", width: "45px" }}
            onClick={() =>
              this.props.setLocationQuery(
                fp.flow(
                  fp.set("cluster_history", this.props.item.id),
                  fp.set("cluster_data", toBase64(toJSON(this.props.item))),
                ),
              )
            }
          />
        ) : (
          <Place
            size={40}
            color={getWarehouseColor(this.props.item.currier)}
            onClick={() =>
              this.props.setLocationQuery(
                fp.flow(
                  fp.set("cluster_history", this.props.item.id),
                  fp.set("cluster_data", toBase64(toJSON(this.props.item))),
                ),
              )
            }
          />
        )}
      </div>
    );
  }
}

OutLitesMarker.propTypes = {
  setLocationQuery: PropTypes.func,
  classes: PropTypes.object,
  item: PropTypes.object,
};

export default enhancer(OutLitesMarker);

import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf, pureComponent } from "../../helpers/HOCUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  getCachedCustomer,
  getCustomerPredictions,
} from "../../api/admin/AdminCustomerApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import ModalPaper from "../../components/ui-core/ModalPaper";
import NeighborhoodsFilterFormBeta from "../../components/map-clustering/NeighborhoodsFilterFormBeta";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  withState("state", "setState", { toggleContainer: null }),
  pureComponent(fp.pick(["state", "filter"])),
  useSheet({
    paper: { minWidth: "400px", maxWidth: "400px" },
  }),
);

AdminNeighborhoodsFilterDialogBetaWrapper.propTypes = {
  state: PropTypes.object,
  setState: PropTypes.func,
  classes: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminNeighborhoodsFilterDialogBetaWrapper(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <ModalPaper
      open={true}
      title={getLocalisationMessage(
        "neighborhoods_filter",
        "Neighborhoods Filter",
      )}
      paperClassName={classes.paper}
      onRequestClose={props.onRequestClose}
    >
      <NeighborhoodsFilterFormBeta
        filter={props.filter}
        onDismiss={props.onRequestClose}
        onFilterChange={props.onFilterChange}
        getCachedCustomer={getCachedCustomer}
        getCachedSupplier={getCachedSupplier}
        getSupplierPredictions={getSupplierPredictions}
        getCustomerPredictions={getCustomerPredictions}
      />
    </ModalPaper>
  );
}

export default enhancer(AdminNeighborhoodsFilterDialogBetaWrapper);

import {
  ORDERS_PER_POSTCODES_BETA_URL,
  ORDERS_PER_POSTCODES_BETA_PATH,
} from "../constants/AdminPathConstants";
import {
  PROFILE_PATH,
  SIGN_IN_PATH,
  SIGN_OUT_PATH,
  DASHBOARD_PATH,
  CHANGE_PASSWORD_PATH,
  RECOVER_PASSWORD_PATH,
} from "../constants/DashboardPathConstants";
import SignOut from "../components/auth/SignOut";
import AdminSignIn from "../containers/admin/AdminSignIn";
import AdminProfile from "../containers/admin/AdminProfile";
import ToolsRoot from "../containers/tools/ToolsRoot";
import ToolsOrdersPerNeighborhoodsContainerBeta from "../containers/tools/ToolsOrdersPerPostcodesContainerBeta";
import ChangePasswordContainer from "../containers/shared/ChangePasswordContainer";
import RecoverPasswordContainer from "../containers/shared/RecoverPasswordContainer";
import Root from "../components/app-core/Root";
import NotFound from "../components/app-core/NotFound";
import { updateQuery } from "../../shared/helpers/UrlUtils";

export default {
  path: "/",
  component: Root,
  indexRoute: {
    onEnter: (state, replace) =>
      replace(updateQuery(ORDERS_PER_POSTCODES_BETA_URL, state.location.query)),
  },
  childRoutes: [
    // Auth.

    {
      path: SIGN_OUT_PATH,
      component: SignOut,
    },
    {
      path: RECOVER_PASSWORD_PATH,
      component: RecoverPasswordContainer,
    },
    {
      path: CHANGE_PASSWORD_PATH,
      component: ChangePasswordContainer,
    },
    {
      path: SIGN_IN_PATH,
      component: AdminSignIn,
    },
    {
      path: DASHBOARD_PATH,
      component: ToolsRoot,
      indexRoute: {
        onEnter: (state, replace) =>
          replace(
            updateQuery(ORDERS_PER_POSTCODES_BETA_URL, state.location.query),
          ),
      },
      childRoutes: [
        // Profile.

        {
          path: PROFILE_PATH,
          component: AdminProfile,
        },

        // Neighborhoods.

        {
          path: ORDERS_PER_POSTCODES_BETA_PATH,
          component: ToolsOrdersPerNeighborhoodsContainerBeta,
        },
      ],
    },

    // Not Found Handler.

    { path: "*", component: NotFound },
  ],
};

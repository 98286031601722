import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { generateColorCode, generateMarkerRadius } from "../MarkerHelper";
import { formatNumber } from "../../../helpers/FormatUtils";
import Text from "../../../components/ui-core/Text";
import { toJSON, toBase64 } from "../../../../shared/helpers/DataSerializer";

const enhancer = compose(
  useSheet({
    markerInGroup: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
      padding: "4px",
      marginLeft: "-10px",
      textAlign: "center",
      fontSize: "13px",
      color: "#fff",
      border: "1px solid #ff0000",
      backgroundColor: "blue",
    },
    markerSingle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
      padding: "4px",
      marginLeft: "-10px",
      textAlign: "center",
      fontSize: "13px",
      color: "#000",
      border: "1px solid #ff0000",
      backgroundColor: "yellow",
    },
  }),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
);

/* eslint-disable-next-line react/prefer-stateless-function */
class Marker extends React.Component {
  static defaultProps = {
    inGroup: false,
  };

  render() {
    const { classes } = this.props;
    const size = generateMarkerRadius(this.props.count, this.props.zoom);
    const colorCode = generateColorCode(size);
    const count = this.props.count / this.props.division;
    return (
      <div>
        {this.props.inGroup ? (
          <Text
            element="div"
            type="align-center"
            className={classes.markerInGroup}
            style={{
              height: `${size > 70 ? 70 : size}px`,
              width: `${size > 70 ? 70 : size}px`,
            }}
          >
            {formatNumber(fp.ceil(count))}
          </Text>
        ) : (
          <Text
            element="div"
            type="align-center"
            className={classes.markerSingle}
            style={{
              height: `${size > 70 ? 70 : size}px`,
              width: `${size > 70 ? 70 : size}px`,
              backgroundColor: colorCode,
            }}
            onClick={() =>
              this.props.setLocationQuery(
                fp.flow(
                  fp.set("cluster_history", this.props.info.id),
                  fp.set("cluster_data", toBase64(toJSON(this.props.info))),
                ),
              )
            }
          >
            {formatNumber(fp.ceil(count))}
          </Text>
        )}
      </div>
    );
  }
}

Marker.propTypes = {
  inGroup: PropTypes.bool,
  count: PropTypes.number,
  setLocationQuery: PropTypes.func,
  classes: PropTypes.object,
  zoom: PropTypes.number,
  division: PropTypes.number,
  info: PropTypes.object,
};

export default enhancer(Marker);

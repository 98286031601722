import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState } from "recompose";
import PropTypes from "prop-types";
import { Drawer, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { Close } from "@material-ui/icons";
import FlexBox from "../ui-core/FlexBox";
import { renderIf } from "../../helpers/HOCUtils";
import { formatNumber } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import Text from "../../components/ui-core/Text";
import { fromJSON, fromBase64 } from "../../../shared/helpers/DataSerializer";

const enhancer = compose(
  renderIf(props => props.activeId > 0),
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return { getLocalisationMessage };
    },
    { showErrorMessage, showSuccessMessage },
  ),
  useSheet({
    paper: {
      minWidth: "1000px",
      maxWidth: "1000px",
      fontFamily: "Blogger Sans",
    },
    container: {
      zIndex: 10,
      padding: "15px",
      paddingTop: "80px",
    },
    alertedPaper: {
      backgroundColor: "#D32F2F",
    },
    successPaper: {
      backgroundColor: "#8BC34A",
    },
    listContainer: {
      height: "320px",
      backgroundColor: "#eee",
    },
    infoContainer: {
      height: "88px",
    },
    root: {
      position: "relative",
    },
    closeIcon: {
      position: "absolute",
      right: "0",
    },
  }),
  withState("state", "setState", { isLoading: false }),
);

MapInfoWindowWrapper.propTypes = {
  classes: PropTypes.object,
  cluster_data: PropTypes.string,
  activeId: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(DataListFilter),
  onRequestRefresh: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function MapInfoWindowWrapper(props) {
  const { classes, getLocalisationMessage } = props;

  const cluster = fromJSON(fromBase64(props.cluster_data));

  return (
    <Drawer
      width={288}
      docked={true}
      openSecondary={true}
      open={Boolean(props.activeId)}
      containerClassName={classes.container}
    >
      {cluster && (
        <FlexBox gutter={8} flex={true} direction="column">
          <FlexBox flex={true} direction="column">
            <IconButton
              touch={true}
              tooltip={getLocalisationMessage("close_window", "Close Window")}
              tooltipPosition="bottom-left"
              className={classes.closeIcon}
              onClick={props.onRequestClose}
            >
              <Close />
            </IconButton>
          </FlexBox>

          {fp.lowerCase(cluster.type) !== "wh" ? (
            <FlexBox flex={true} direction="column">
              <FlexBox flex={true}>
                <Text element="div">
                  <strong>{getLocalisationMessage("city", "City")}: </strong>{" "}
                  {cluster.city}
                </Text>
              </FlexBox>
              <FlexBox flex={true}>
                <Text element="div">
                  <strong>
                    {getLocalisationMessage("neighborhood", "Neighborhood")}:{" "}
                  </strong>{" "}
                  {cluster.neighborhood}
                </Text>
              </FlexBox>
              <FlexBox flex={true}>
                <Text element="div">
                  <strong>
                    {getLocalisationMessage("latitude", "Latitude")}:{" "}
                  </strong>{" "}
                  {cluster.lat}
                </Text>
              </FlexBox>
              <FlexBox flex={true}>
                <Text element="div">
                  <strong>
                    {getLocalisationMessage("longitude", "Longitude")}:{" "}
                  </strong>{" "}
                  {cluster.lng}
                </Text>
              </FlexBox>
              <FlexBox flex={true}>
                <Text element="div">
                  <strong>{getLocalisationMessage("count", "Count")}: </strong>{" "}
                  {formatNumber(cluster.count)}
                </Text>
              </FlexBox>
            </FlexBox>
          ) : (
            <FlexBox flex={true} direction="column">
              <FlexBox flex={true}>
                <Text element="h5" style={{ marginBottom: "20px" }}>
                  {cluster.name_en}
                </Text>
              </FlexBox>
              <FlexBox flex={true}>
                <Text element="div">
                  <strong>
                    {getLocalisationMessage("arabic", "Arabic")}:{" "}
                  </strong>{" "}
                  {cluster.name_ar}
                </Text>
              </FlexBox>
              <FlexBox flex={true}>
                <Text element="div">
                  <strong>
                    {getLocalisationMessage("hub_code", "Hub Code")}:{" "}
                  </strong>{" "}
                  {cluster.hub_code}
                </Text>
              </FlexBox>
              <FlexBox flex={true}>
                <Text element="div">
                  <strong>
                    {getLocalisationMessage("country", "Country")}:{" "}
                  </strong>{" "}
                  {cluster.country}
                </Text>
              </FlexBox>
              <FlexBox flex={true}>
                <Text element="div">
                  <strong>{getLocalisationMessage("city", "City")}: </strong>{" "}
                  {cluster.city}
                </Text>
              </FlexBox>
              <FlexBox flex={true}>
                <Text element="div">
                  <strong>
                    {getLocalisationMessage("current_value", "Current Value")}:{" "}
                  </strong>{" "}
                  {cluster.current_vol}
                </Text>
              </FlexBox>
              <FlexBox flex={true}>
                <Text element="div">
                  <strong>
                    {getLocalisationMessage(
                      "yearly_end_value",
                      "Yearly End Value",
                    )}
                    :{" "}
                  </strong>{" "}
                  {cluster.yr_end_vol}
                </Text>
              </FlexBox>
              <FlexBox flex={true}>
                <Text element="div">
                  <strong>{getLocalisationMessage("area", "Area")}: </strong>{" "}
                  {cluster.area}
                </Text>
              </FlexBox>
              <FlexBox flex={true}>
                <Text element="div">
                  <strong>
                    {getLocalisationMessage("postcode", "Postcode")}:{" "}
                  </strong>{" "}
                  {cluster.post_code}
                </Text>
              </FlexBox>
              <FlexBox flex={true}>
                <Text element="div">
                  <strong>
                    {getLocalisationMessage("landmarks", "Landmarks")}:{" "}
                  </strong>{" "}
                  {cluster.landmarks}
                </Text>
              </FlexBox>
              <FlexBox flex={true}>
                <Text element="div">
                  <strong>{getLocalisationMessage("phone", "Phone")}: </strong>{" "}
                  {cluster.tel}
                </Text>
              </FlexBox>
              <FlexBox flex={true}>
                <Text element="div">
                  <strong>
                    {getLocalisationMessage("latitude", "Latitude")}:{" "}
                  </strong>{" "}
                  {cluster.lat}
                </Text>
              </FlexBox>
              <FlexBox flex={true}>
                <Text element="div">
                  <strong>
                    {getLocalisationMessage("longitude", "Longitude")}:{" "}
                  </strong>{" "}
                  {cluster.lng}
                </Text>
              </FlexBox>
              <FlexBox flex={true}>
                <Text element="div">
                  <strong>
                    {getLocalisationMessage("working_hours", "Working Hours")}:{" "}
                  </strong>{" "}
                  {cluster.working_hours}
                </Text>
              </FlexBox>
              <FlexBox flex={true}>
                <Text element="div">
                  <strong>
                    {getLocalisationMessage("station_lead", "Station Lead")}:{" "}
                  </strong>{" "}
                  {cluster.station_lead}
                </Text>
              </FlexBox>
              <FlexBox flex={true}>
                <Text element="div">
                  <strong>
                    {getLocalisationMessage("mobile_number", "Mobile Number")}:{" "}
                  </strong>{" "}
                  {cluster.mobile_number}
                </Text>
              </FlexBox>
              <FlexBox flex={true}>
                <Text element="div">
                  <strong>
                    {getLocalisationMessage("email_address", "Email Address")}:{" "}
                  </strong>{" "}
                  {cluster.email_address}
                </Text>
              </FlexBox>
              <FlexBox flex={true}>
                <Text element="div">
                  <strong>
                    {getLocalisationMessage("courier", "Courier")}:{" "}
                  </strong>{" "}
                  {cluster.currier}
                </Text>
              </FlexBox>
            </FlexBox>
          )}
        </FlexBox>
      )}
    </Drawer>
  );
}

export default enhancer(MapInfoWindowWrapper);

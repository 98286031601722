import React from "react";
import { getContext } from "recompose";
import PropTypes from "prop-types";
import FormAbstractChips from "./FormAbstractChips";
import DataListFilter from "../../helpers/DataListFilter";
import { ACTIVE } from "../../constants/OverallStatus";

const baseFilter = new DataListFilter({ page: 0, size: 20, status: ACTIVE });
const enhancer = getContext({
  getCachedSupplier: PropTypes.func.isRequired,
  getSupplierPredictions: PropTypes.func.isRequired,
});

FormSupplierChips.propTypes = {
  getCachedSupplier: PropTypes.func,
  getSupplierPredictions: PropTypes.func,

  fullWidth: PropTypes.bool,
  autoWidth: PropTypes.bool,
  canAutoPosition: PropTypes.bool,

  maxHeight: PropTypes.number,

  openOnFocus: PropTypes.bool,
  clearOnBlur: PropTypes.bool,
  maxItems: PropTypes.number,
  maxSearchResults: PropTypes.number,

  validate: PropTypes.func,

  hintText: PropTypes.node,
  label: PropTypes.node,

  name: PropTypes.string.isRequired,

  filter: PropTypes.object,
  style: PropTypes.object,
};

function FormSupplierChips({
  getCachedSupplier,
  getSupplierPredictions,
  filter,
  ...props
}) {
  return (
    <FormAbstractChips
      {...props}
      getValue={getCachedSupplier}
      getPredictions={searchText =>
        getSupplierPredictions(
          baseFilter.setSearch(searchText).setValueMap(filter),
        )
      }
    />
  );
}

export default enhancer(FormSupplierChips);

import React from "react";
import { compose, withState, getContext } from "recompose";
import PropTypes from "prop-types";
import supercluster from "points-cluster";
import GoogleMapReact from "google-map-react";
import Marker from "../Marker";
import { getCountryMapOptions } from "../MarkerHelper";
import ClusterMarker from "../ClusterMarker";
import OutLitesMarker from "../components/OutLitesMarker";
import DataListFilter from "../../../helpers/DataListFilter";
import sampleData from "../assets/sample_data.json";

const MAP = {
  defaultZoom: 8,
  center: { lat: 25.2048, lng: 55.2708 },
  options: {
    styles: { position: "relative", width: "100%", height: "100%" },
    maxZoom: 19,
  },
};

const enhancer = compose(
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  withState("state", "setState", {
    mapOptions: { center: MAP.defaultCenter, zoom: MAP.defaultZoom },
    clusters: [],
  }),
);

class GoogleMap extends React.Component {
  state = {
    mapOptions: { center: MAP.defaultCenter, zoom: MAP.defaultZoom },
    clusters: [],
    wh: sampleData,
  };

  componentDidUpdate(prevProps) {
    const { densityList: densityList1 } = prevProps;
    const { densityList: densityList2 } = this.props;

    if (densityList1 !== densityList2) {
      this.createClusters();
      this.render();
    }
  }

  getClusters = () => {
    const clusters = supercluster(this.props.densityList, {
      minZoom: 0,
      maxZoom: 30,
      radius: 60,
    });

    return clusters(this.state.mapOptions);
  };

  createClusters = props => {
    this.setState({
      clusters: this.state.mapOptions.bounds
        ? this.getClusters(props).map(({ wx, wy, numPoints, points }) => ({
            lat: wy,
            lng: wx,
            numPoints,
            id: `${numPoints}_${points[0].id}`,
            points,
          }))
        : [],
    });
  };

  handleMapChange = ({ center, zoom, bounds }) => {
    this.setState(
      {
        mapOptions: {
          center,
          zoom,
          bounds,
        },
      },
      () => {
        this.createClusters(this.props);
      },
    );
  };

  render() {
    const mapOptions = getCountryMapOptions(
      this.props.filter.getValue("country"),
    );

    return (
      <GoogleMapReact
        defaultZoom={mapOptions.zoom}
        center={mapOptions.center}
        options={MAP.options}
        onChange={this.handleMapChange}
        yesIWantToUseGoogleMapApiInternals={true}
        bootstrapURLKeys={{ key: "AIzaSyDm7b_spFea02ZcwDwpvJQcAhIycCbCUCQ" }}
      >
        {this.state.clusters.map(item => {
          if (item.numPoints === 1) {
            return (
              <Marker
                division={this.props.division}
                key={item.id}
                lat={item.points[0].lat}
                lng={item.points[0].lng}
                count={item.points[0].count}
                info={item.points[0]}
                zoom={this.state.mapOptions.zoom}
              />
            );
          }

          return (
            <ClusterMarker
              division={this.props.division}
              key={item.id}
              lat={item.lat}
              lng={item.lng}
              points={item.points}
              zoom={this.state.mapOptions.zoom}
            />
          );
        })}

        {!this.props.filter.getBoolValue("hidePoints") &&
          this.state.wh.map(item => (
            <OutLitesMarker
              key={item.id}
              lat={item.lat}
              lng={item.lng}
              item={item}
            />
          ))}
      </GoogleMapReact>
    );
  }
}

GoogleMap.propTypes = {
  densityList: PropTypes.array,
  division: PropTypes.number,
  filter: PropTypes.instanceOf(DataListFilter),
};

export default enhancer(GoogleMap);

import React from "react";
import PropTypes from "prop-types";
import { BlurCircular } from "@material-ui/icons";
import AppLayout from "../app-layout/AppLayout";
import AppSidebar from "../app-sidebar/AppSidebar";
import AppSidebarLink from "../app-sidebar/AppSidebarLink";
import AppSidebarBlock from "../app-sidebar/AppSidebarBlock";
import AppSidebarProfileBlock from "../app-sidebar/AppSidebarProfileBlock";
import { ORDERS_PER_POSTCODES_BETA_URL } from "../../constants/AdminPathConstants";

export const sidebar = (
  <AppSidebar>
    <AppSidebarBlock title="Density Map">
      <AppSidebarLink
        to={ORDERS_PER_POSTCODES_BETA_URL}
        leftIcon={<BlurCircular />}
      >
        Orders in NBHD
      </AppSidebarLink>
    </AppSidebarBlock>

    <AppSidebarProfileBlock />
  </AppSidebar>
);

ToolsAppLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  appBarRightAction: PropTypes.node,
  isToolViewer: PropTypes.bool,
};

function ToolsAppLayout(props) {
  return <AppLayout {...props} sidebar={sidebar} />;
}

export default ToolsAppLayout;

import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { signIn } from "../../actions/CustomerAuthActions";
import ResponseError from "../../helpers/ResponseError";
import SignInForm from "../../components/auth/SignInForm";
import NavigateFromSignIn from "../../components/auth/NavigateFromSignIn";
import {
  isTokenValid,
  isTokenPersisting,
} from "../../../shared/reducers/AuthReducer";

const enhancer = compose(
  connect(
    state => ({
      isLoading: isTokenPersisting(state),
      isTokenValid: isTokenValid(state),
    }),
    { signIn },
  ),
);

AdminSignIn.propTypes = {
  signIn: PropTypes.func,
  isLoading: PropTypes.bool,
  isTokenValid: PropTypes.bool,
};

function AdminSignIn(props) {
  if (props.isTokenValid) {
    return <NavigateFromSignIn />;
  }

  return (
    <SignInForm
      isLoading={props.isLoading}
      onSubmit={values => props.signIn(values).catch(ResponseError.throw)}
    />
  );
}

export default enhancer(AdminSignIn);

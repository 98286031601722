import { Observable } from "rxjs";
import React from "react";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";
import { FilterList } from "@material-ui/icons";
import { yellow } from "@material-ui/core/colors";
import { isEqualData } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { toNeighborhoodBetaFilter } from "../../helpers/NieghborhoodsFilterBetaMapper";
import { getDensityList } from "../../api/admin/AdminPostcodesApi";
import AdminNeighborhoodsFilterDialogBetaWrapper from "../../wrappers/admin/AdminNeighborhoodsFilterDialogBetaWrapper";
import ToolsAppLayout from "../../components/tools/ToolsAppLayout";
import FlexBox from "../../components/ui-core/FlexBox";
import PageLoading from "../../components/ui-core/PageLoading";
import GoogleMap from "../../components/map-clustering/GoogleMap";
import { divideBy } from "../../components/map-clustering/MarkerHelper";
import MapInfoWindowWrapper from "../../components/map-clustering/MapInfoWindowWrapper";

const enhancer = compose(
  useSheet({
    appBarRightAction: {
      fontSize: "15px",
      color: "white",
      marginTop: "0px",
    },
    appBarRightActionInfo: {
      fontSize: "18px",
      marginRight: "10px",
    },
    appBarRightActionInfoOrderCount: {
      color: yellow[500],
      display: "block",
      paddingLeft: "10px",
    },
    appBarRightActionToggle: {
      whiteSpace: "nowrap",
      width: "auto",
      float: "right",
      marginLeft: "12px",
    },
  }),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const filterStream = propsStream
          .pluck("location", "query")
          .map(
            fp.flow(toNeighborhoodBetaFilter, (filter: DataListFilter) =>
              filter.setValue(
                "country",
                filter.getValue("country") ? filter.getValue("country") : "ae",
              ),
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(filterStream, (props, filter) => ({
          ...props,
          filter,
        }));
      },
      propsStream => {
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const listResponseStream = propsStream
          .distinctUntilKeyChanged("filter")
          .switchMap(props =>
            getDensityList(props.filter)
              .repeatWhen(() => onRequestRefreshStream)
              .catch(error => Observable.of({ error })),
          )
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  pending: response.get("pending", false),
                  list: response.getIn(["payload", "data"]),
                }),
            ),
          );

        return propsStream
          .combineLatest(listResponseStream, (props, listResponse) => ({
            ...props,
            onRequestRefresh,
            densityList: listResponse.get("list")
              ? listResponse.get("list").toJS()
              : null,
            isLoading: listResponse.get("pending"),
          }))
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

ToolsOrdersPerPostcodesContainerBeta.propTypes = {
  location: PropTypes.object,
  classes: PropTypes.object,
  setLocationQuery: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,

  isLoading: PropTypes.bool,
  densityList: PropTypes.array,
  onRequestRefresh: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
};

function ToolsOrdersPerPostcodesContainerBeta(props) {
  const {
    location: { query },
    densityList,
  } = props;
  const division = divideBy(props.filter.getValue("period"));

  return (
    <ToolsAppLayout
      title="Orders Per Neighborhoods BETA"
      appBarRightAction={
        <FlexBox
          flex={true}
          align="center"
          justify="flex-end"
          className={props.classes.appBarRightAction}
        >
          <IconButton
            onClick={() => props.setLocationQuery(fp.set("show_filter", true))}
            iconStyle={{ color: "white" }}
          >
            <FilterList />
          </IconButton>
        </FlexBox>
      }
    >
      <PageLoading isLoading={props.isLoading} />

      {query.show_filter === "true" && (
        <AdminNeighborhoodsFilterDialogBetaWrapper
          open={true}
          filter={props.filter}
          onFilterChange={filter => {
            props.setLocationQueryFilter(filter);
            props.setLocationQuery(fp.unset("show_filter"));
          }}
          onRequestClose={() => props.setLocationQuery(fp.unset("show_filter"))}
        />
      )}

      <MapInfoWindowWrapper
        activeId={fp.toFinite(query.cluster_history)}
        cluster_data={query.cluster_data}
        onRequestClose={() => {
          props.setLocationQuery(
            fp.flow(fp.unset("cluster_history")),
            fp.flow(fp.unset("cluster_data")),
          );
        }}
      />

      {densityList && (
        <GoogleMap
          division={division}
          densityList={densityList}
          filter={props.filter}
        />
      )}
    </ToolsAppLayout>
  );
}

export default enhancer(ToolsOrdersPerPostcodesContainerBeta);

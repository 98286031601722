import React from "react";
import { fromJS } from "immutable";
import { getContext } from "recompose";
import PropTypes from "prop-types";
import DataListFilter from "../../helpers/DataListFilter";
import { ACTIVE } from "../../constants/OverallStatus";
import FormCustomerAbstractChips from "./FormCustomerAbstractChips";

const baseFilter = new DataListFilter({ page: 0, size: 20, status: ACTIVE });
const enhancer = getContext({
  getCachedCustomer: PropTypes.func.isRequired,
  getCustomerPredictions: PropTypes.func.isRequired,
});

FormCustomerChips.propTypes = {
  getCachedCustomer: PropTypes.func,
  getCustomerPredictions: PropTypes.func,

  fullWidth: PropTypes.bool,
  autoWidth: PropTypes.bool,
  canAutoPosition: PropTypes.bool,

  maxHeight: PropTypes.number,

  openOnFocus: PropTypes.bool,
  clearOnBlur: PropTypes.bool,
  maxItems: PropTypes.number,
  maxSearchResults: PropTypes.number,

  onGetValue: PropTypes.func,

  validate: PropTypes.func,

  hintText: PropTypes.node,
  label: PropTypes.node,

  name: PropTypes.string.isRequired,
  marketplaceId: PropTypes.number,

  variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
  size: PropTypes.oneOf(["medium", "small"]),
};

FormCustomerChips.defaultProps = {
  marketplaceId: null,
  variant: "outlined",
  size: "small",
};

function FormCustomerChips({
  getCachedCustomer,
  getCustomerPredictions,
  marketplaceId,
  ...props
}) {
  return (
    <FormCustomerAbstractChips
      {...props}
      getValue={value =>
        getCachedCustomer(value).map(item =>
          fromJS({
            ...item.toJS(),
            id: item.get("customer_id"),
          }),
        )
      }
      getPredictions={searchText =>
        getCustomerPredictions(
          baseFilter
            .setSearch(searchText)
            .setValue(
              "marketplaceId",
              marketplaceId === 0 || marketplaceId === null
                ? null
                : marketplaceId,
            )
            .setValue(
              "multi_marketplace",
              !(marketplaceId === 0 || marketplaceId === null),
            ),
        )
      }
    />
  );
}

export default enhancer(FormCustomerChips);
